import "./static/bootstrap.min.css"
import "./static/fontawesome.min.css"
// import "./static/jquery.min.js"
// import "./static/bootstrap.min.js"
// custom typefaces
// import "typeface-montserrat"
// import "typeface-merriweather"

// import "prismjs/themes/prism-twilight.css"

import "./static/mystyle.css"

import "./static/css/heading.css"
import "./static/css/body.css"
import "./static/css/styles.css"